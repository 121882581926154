<template>
  <div class="common-product">
    <div class="goods-main">
      <div class="top-filter flex-center-start">
        <div
          class="option"
          :class="tabIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          {{ $t('zong-he') }}
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
        >
          {{ $t('xiao-shou-liang') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 2 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 2 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 3 ? 'active' : ''"
          @click="changeTab(3)"
        >
          {{ $t('jia-ge') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 3 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 3 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
        <div
          class="option flex-center"
          :class="tabIndex == 4 ? 'active' : ''"
          @click="changeTab(4)"
        >
          {{ $t('shang-xin') }}
          <div class="flex-column-center icon">
            <i
              class="el-icon-caret-top"
              :class="tabIndex == 4 && sortType == 'asc' ? 'active' : ''"
            ></i>
            <i
              class="el-icon-caret-bottom"
              :class="tabIndex == 4 && sortType == 'desc' ? 'active' : ''"
            ></i>
          </div>
        </div>
      </div>
      <goodsList :list="goodsList"></goodsList>

      <div class="flex-center user-pager mt-20">
        <el-pagination
          background
          @current-change="currentChange"
          :current-page="page.current"
          :page-size="page.size"
          layout=" prev, pager,  next"
          :total="page.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import goodsList from '@/components/goodsList'
import { shopProductList } from '@/api/shop'
export default {
  components: {
    goodsList
  },
  data() {
    return {
      tabIndex: 1,
      sortType: 'desc',
      goodsList: [],
      form: {
        sort: ''
      },
      page: {
        current: 1,
        size: 30,
        total: 0
      },
      keyword: ''
    }
  },
  computed: {
    lang() {
      return this.$store.state.lang
    },
    categoryList() {
      return this.$store.state.categoryList
    }
  },
  watch: {
    $route(val) {
      this.keyword = this.$route.query.keyword
      this.initGoods()
    }
  },
  mounted() {
    this.keyword = this.$route.query.keyword
    this.initGoods()
  },
  methods: {
    currentChange(page) {
      this.page.current = page
      this.initGoods()
    },
    initGoods() {
      shopProductList({
        current: this.page.current,
        pageSize: this.page.size,
        Title: this.keyword,
        Sort: this.form.sort
      }).then((res) => {
        this.goodsList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeShow(data, i) {
      let show = !data.show
      this.$set(this.categoryList[i], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeShowSub(data, i, index) {
      if (!data.children || data.children.length == 0) {
        return
      }
      let show = !data.show
      this.$set(this.categoryList[i].children[index], 'show', show)
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    changeTab(i) {
      if (i == this.tabIndex) {
        this.sortType = this.sortType == 'asc' ? 'desc' : 'asc'
      } else {
        this.sortType = 'desc'
        this.tabIndex = i
      }
      this.form.sort =
        i == 1
          ? ''
          : i == 2
          ? 'sales'
          : i === 3
          ? this.sortType == 'desc'
            ? 'MaxPrice'
            : 'MinPrice'
          : ''
      this.page.current = 1
      this.initGoods()
    },
    chooseCategory(data) {
      this.form.category = data.ID
      this.page.current = 1
      this.initGoods()
    },
    showAll() {
      this.form.category = ''
      this.page.current = 1
      this.initGoods()
    }
  }
}
</script>